import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import {
  DsaBackToTopButtonModule,
  DsaCardModule,
  DsaFooterModule,
  DsaInfoCardModule,
  DsaInputFieldModule,
  DsaObjectHeaderModule,
  DsaSpinnerButtonModule,
  DsaToastModule
} from '@dsa/design-system-angular';
import { DsaAccordionModule } from '@dsa/design-system-angular/accordion';
import { DsaButtonModule } from '@dsa/design-system-angular/button';
import { DsaCheckboxModule } from '@dsa/design-system-angular/checkbox';
import { DsaDatepickerModule } from '@dsa/design-system-angular/datepicker';
import { DsaDialogModule } from '@dsa/design-system-angular/dialog';
import { DsaFormFieldModule } from '@dsa/design-system-angular/form-field';
import { DsaIconModule } from '@dsa/design-system-angular/icon';
import { DsaIconButtonModule } from '@dsa/design-system-angular/icon-button';
import { DsaSelectModule } from '@dsa/design-system-angular/select';
import { DsaSingleFileUploadModule } from '@dsa/design-system-angular/single-file-upload';
import { DsaSpinnerModule } from '@dsa/design-system-angular/spinner';
import { DsaTableModule } from '@dsa/design-system-angular/table';
import { DsaTabsModule } from '@dsa/design-system-angular/tabs';
import { DsaTimepickerModule } from '@dsa/design-system-angular/timepicker';
import { DsaToggleButtonGroupModule } from '@dsa/design-system-angular/toggle-button-group';
import { DsaToggleSwitchModule } from '@dsa/design-system-angular/toggle-switch';
import { DsaTooltipModule } from '@dsa/design-system-angular/tooltip';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { TransferinformationTabComponent } from '../shared/components/transferinformation-tab/transferinformation-tab.component';
import { BookingmemosTabComponent } from './components/bookingmemos-tab/bookingmemos-tab.component';
import { BookingViewerComponent } from './components/bookingviewer/bookingviewer.component';
import { DateTimePickerComponent } from './components/datetimepicker/datetimepicker.component';
import { FlightinformationTabComponent } from './components/flightinformation-tab/flightinformation-tab.component';
import { HotelinformationTabComponent } from './components/hotelinformation-tab/hotelinformation-tab.component';
import { NoResultComponent } from './components/no-result/no-result.component';
import { PassengerInformationTabComponent } from './components/passenger-information-tab/passenger-information-tab.component';
import { AutoFocusDirective } from './directives/auto-focus.directive';

@NgModule({
  declarations: [
    NoResultComponent,
    AutoFocusDirective,
    DateTimePickerComponent,
    PassengerInformationTabComponent,
    FlightinformationTabComponent,
    HotelinformationTabComponent,
    TransferinformationTabComponent,
    BookingmemosTabComponent,
    BookingViewerComponent
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    DsaSpinnerModule,
    DsaToastModule,
    DsaButtonModule,
    DsaSpinnerButtonModule,
    DsaDatepickerModule,
    DsaTimepickerModule,
    DsaCheckboxModule,
    DsaIconModule,
    DsaIconButtonModule,
    DsaToggleSwitchModule,
    DsaFooterModule,
    DsaBackToTopButtonModule,
    DsaToggleButtonGroupModule,
    DsaInputFieldModule,
    DsaTooltipModule,
    ReactiveFormsModule,
    DsaSelectModule,
    DsaTabsModule,
    DsaCardModule,
    DsaTableModule,
    DsaInfoCardModule,
    DsaAccordionModule,
    DsaObjectHeaderModule,
    DsaTabsModule,
    MatDialogModule,
    MatDividerModule,
    NgxJsonViewerModule,
    DsaFormFieldModule
  ],
  exports: [
    // modules
    FlexLayoutModule,
    DsaSpinnerModule,
    DsaToastModule,
    DsaButtonModule,
    DsaIconModule,
    DsaIconButtonModule,
    DsaSpinnerButtonModule,
    DsaDatepickerModule,
    DsaTimepickerModule,
    DsaTabsModule,
    DsaToggleSwitchModule,
    DsaCardModule,
    DsaCheckboxModule,
    DsaFooterModule,
    DsaToggleButtonGroupModule,
    DsaBackToTopButtonModule,
    DsaTooltipModule,
    DsaInputFieldModule,
    ReactiveFormsModule,
    DsaSelectModule,
    DsaInfoCardModule,
    DsaTableModule,
    DsaAccordionModule,
    DsaObjectHeaderModule,
    DsaTabsModule,
    DsaDialogModule,
    DsaSingleFileUploadModule,
    MatDialogModule,
    MatDividerModule,
    NgxJsonViewerModule,
    DsaFormFieldModule,
    // components
    NoResultComponent,
    DateTimePickerComponent,
    PassengerInformationTabComponent,
    FlightinformationTabComponent,
    HotelinformationTabComponent,
    TransferinformationTabComponent,
    BookingmemosTabComponent,
    BookingViewerComponent,
    // directives
    AutoFocusDirective
  ]
})
export class SharedModule {}
