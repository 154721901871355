import { Injectable } from '@angular/core';
import { ToastActions } from '@app/modules/core/actions/toast.action';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { saveAs } from 'file-saver';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { NavigationActions } from '../../shared/actions/navigation.actions';
import { FileImportActions } from '../actions/file-import.actions';
import { HotelChainActions } from '../actions/hotel-chain.actions';
import { HotelChainService } from '../services/hotel-chain.service';

@Injectable()
export class HotelChainEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly hotelChainService: HotelChainService
  ) {}

  onRequestHotelChains$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HotelChainActions.searchHotelChains),
      switchMap(req =>
        this.hotelChainService.search(req.force, req.filterParams).pipe(
          map(searchOutput => {
            if (searchOutput) {
              return HotelChainActions.searchHotelChainsSuccess({
                searchOutput
              });
            }
          }),
          catchError(error =>
            of(ToastActions.errorToast({ message: error.message }))
          )
        )
      )
    )
  );

  onGetHotelChain$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HotelChainActions.getHotelChain),
      switchMap(req => {
        return this.hotelChainService
          .get(encodeURIComponent(req.hotelChainCode), req.supplier)
          .pipe(
            map(hotelChain =>
              HotelChainActions.getHotelChainSuccess({
                hotelChain
              })
            ),
            catchError(error =>
              of(ToastActions.errorToast({ message: error.message }))
            )
          );
      })
    )
  );

  onUpsertHotelChain$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HotelChainActions.upsertHotelChain),
      switchMap(req => {
        return this.hotelChainService.upsert(req.hotelChain).pipe(
          map(_ =>
            HotelChainActions.upsertHotelChainSuccess({
              successMessage: req.successMessage
            })
          ),
          catchError(error =>
            of(
              HotelChainActions.upsertHotelChainError({
                error: error.error
              })
            )
          )
        );
      })
    )
  );

  onUpsertHotelChainNavigateBackOnSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HotelChainActions.upsertHotelChainNavigateBackOnSuccess),
      switchMap(req => {
        return this.hotelChainService.upsert(req.hotelChain).pipe(
          switchMap(_ => [
            HotelChainActions.upsertHotelChainSuccess({
              successMessage: req.successMessage
            }),
            NavigationActions.navigateBack()
          ]),
          catchError(error =>
            of(
              HotelChainActions.upsertHotelChainError({
                error: error.error
              })
            )
          )
        );
      })
    )
  );

  onSuccessfulUpsertHotelChain$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HotelChainActions.upsertHotelChainSuccess),
      map(req =>
        ToastActions.successToast({
          message: req.successMessage ?? 'Hotel Chain saved'
        })
      )
    )
  );

  onErrorUpsertHotelChain$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HotelChainActions.upsertHotelChainError),
      map(req =>
        ToastActions.errorToast({
          message: req.error
        })
      )
    )
  );

  onDeleteHotelChain$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HotelChainActions.deleteHotelChain),
      switchMap(req => {
        return this.hotelChainService.delete(req.hotelChainIndex).pipe(
          switchMap(_ => [
            HotelChainActions.deleteHotelChainSuccess(),
            ToastActions.successToast({
              message: 'HotelChain deleted'
            }),
            HotelChainActions.searchHotelChains({ force: true })
          ]),
          catchError(error =>
            of(ToastActions.errorToast({ message: error.error }))
          )
        );
      })
    )
  );

  onUploadExcelFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HotelChainActions.uploadExcelFile),
      switchMap(req =>
        this.hotelChainService
          .excelImport(
            req.excelFile,
            req.hotelChainCode,
            req.supplier,
            req.overrideHotels,
            req.templateType
          )
          .pipe(
            map(_ =>
              FileImportActions.closeUploadFileDialog({ HotelChain: _ })
            ),
            catchError(error =>
              error.error instanceof ProgressEvent
                ? of(
                    FileImportActions.importFail({
                      errors:
                        'Please check that the file is not open in a program and try again.\r\n ------ \r\nThe server may have been temporarily unavailable.\r\nPlease try again later or contact support.'
                    })
                  )
                : of(FileImportActions.importFail({ errors: error.error }))
            )
          )
      )
    )
  );

  onRefreshHotelChainListAfterExcelUpload$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        FileImportActions.closeUploadFileDialog,
        FileImportActions.importFail
      ),
      map(_ => HotelChainActions.searchHotelChains({ force: true }))
    )
  );

  onGetHotelChainDropdown$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HotelChainActions.getDropdown),
      switchMap(_ =>
        this.hotelChainService.getDropdown().pipe(
          map(hotelChainDropdown =>
            HotelChainActions.getDropdownSuccess({
              hotelChainDropdown
            })
          ),
          catchError(error =>
            of(ToastActions.errorToast({ message: error.message }))
          )
        )
      )
    )
  );

  setSaveButtonDisabled$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HotelChainActions.setSaveButtonDisabled),
      map(HotelChainActions.setSaveButtonDisabledSuccess)
    )
  );

  validateHotelChain$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HotelChainActions.validateHotelChain),
      mergeMap(({ hotelChainCode: hotelchainCode, supplier }) =>
        this.hotelChainService.exists(hotelchainCode, supplier).pipe(
          mergeMap(response => {
            if (response) {
              const errorMessage =
                'This hotelchain code already exists for this supplier. Please provide another hotelchain code.';
              return [
                HotelChainActions.validateHotelChainFailure({
                  errorMessage: errorMessage
                }),
                ToastActions.errorToast({
                  message: errorMessage
                }),
                HotelChainActions.setSaveButtonDisabled({
                  isDisabled: response
                })
              ];
            }
            return of(
              HotelChainActions.setSaveButtonDisabled({
                isDisabled: response
              })
            );
          })
        )
      )
    )
  );

  downloadTemplate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HotelChainActions.downloadTemplate),
      switchMap(({ templateType }) =>
        this.hotelChainService.getTemplate(templateType).pipe(
          map(fileData =>
            HotelChainActions.downloadTemplateComplete({
              fileData,
              templateType
            })
          ),
          catchError(error =>
            of(ToastActions.errorToast({ message: error.message }))
          )
        )
      )
    )
  );

  downloadTemplateComplete$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(HotelChainActions.downloadTemplateComplete),
        map(parameter =>
          saveAs(
            parameter.fileData.body,
            `${parameter.templateType.toString()}-Import.xlsx`
          )
        )
      ),
    { dispatch: false }
  );
}
