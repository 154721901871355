import { SignalREffects } from 'ngrx-signalr-core';
import { MasterDataEffects } from '../../booking/modules/mapping/effects/masterdata.effects';
import { ReceivedEffects } from '../../booking/modules/storage/effects/received.effects';
import { AriEffects } from '../../dynamic-connect/effects/ari.effects';
import { ExternalMappingEffects } from '../../dynamic-connect/effects/externalmapping.effects';
import { FileImportEffects } from '../../dynamic-connect/effects/file-import.effects';
import { HotelChainEffects } from '../../dynamic-connect/effects/hotel-chain.effects';
import { HotelEffects } from '../../dynamic-connect/effects/hotel.effects';
import { JobNotificationEffects } from '../../dynamic-connect/job-notifications-display/effects/job-notification.effects';
import { SuggestEffects as SuggestBookingHistoryEffects } from '../../exception/effects/bookingexceptionhistorysuggester.effects';
import { SuggestEffects as SuggestBookingEffects } from '../../exception/effects/bookingexceptionsuggester.effects';
import { SuggestEffects as SuggestBusinessEffects } from '../../exception/effects/dcco-exceptionsuggester.effects';
import { FlightInfoEffects } from '../../exception/effects/flightinfo.effects';
import { HotelChainDropdownEffects } from '../../exception/effects/hotelchain-dropdown.effects';
import { MemoEffects } from '../../exception/effects/memo.effects';
import { BookingEffects } from '../../shared/effects/booking.effects';
import { NavigationEffects } from '../../shared/effects/navigation.effects';
import { CoreEffects } from './core.effects';
import { DataEffects } from './data.effects';
import { InstanaEffects } from './instana.effects';
import { ToastEffects } from './toast.effects';

export const Effects = [
  CoreEffects,
  DataEffects,
  ToastEffects,
  InstanaEffects,
  SuggestBusinessEffects,
  SuggestBookingEffects,
  SuggestBookingHistoryEffects,
  MemoEffects,
  BookingEffects,
  FlightInfoEffects,
  MasterDataEffects,
  HotelChainEffects,
  FileImportEffects,
  HotelChainDropdownEffects,
  HotelEffects,
  AriEffects,
  ExternalMappingEffects,
  ReceivedEffects,
  SignalREffects,
  JobNotificationEffects,
  NavigationEffects
];
