import { HttpResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { ExternalMapping } from '../data/externalmapping';
import { FilterParams } from '../data/filterparam';
import { ListResult } from '../data/listResult';
import { TemplateType } from '../data/templateType';

const getExternalMappings = createAction(
  '[ExternalMapping] Get ExternalMappings',
  props<{ filterParams?: FilterParams }>()
);

const getExternalMappingsSuccess = createAction(
  '[ExternalMapping] Get ExternalMappings Success',
  props<{ listResult: ListResult<ExternalMapping> }>()
);

const deleteExternalMapping = createAction(
  '[ExternalMapping] Delete ExternalMapping',
  props<{ externalMapping: ExternalMapping }>()
);

const deleteExternalMappingSuccess = createAction(
  '[ExternalMapping] Delete ExternalMapping Success'
);

const selectExternalMapping = createAction(
  '[ExternalMapping] Select ExternalMapping',
  props<{ externalMapping: ExternalMapping }>()
);

const upsertExternalMapping = createAction(
  '[ExternalMapping] Upsert ExternalMapping',
  props<{ externalMapping: ExternalMapping }>()
);

const upsertExternalMappingSuccess = createAction(
  '[ExternalMapping] Upsert ExternalMapping Success'
);

const unselectExternalMapping = createAction(
  '[ExternalMapping] Unselect ExternalMapping'
);

const uploadExcelFile = createAction(
  '[ExternalMapping] Upload ExternalMapping Excel File',
  props<{
    excelFile: File;
  }>()
);

const downloadTemplate = createAction(
  '[ExternalMapping] Download Template',
  props<{ templateType: TemplateType }>()
);

const downloadTemplateComplete = createAction(
  '[ExternalMapping] Download Template Complete',
  props<{ fileData: HttpResponse<Blob>; templateType: TemplateType }>()
);

export const ExternalMappingActions = {
  getExternalMappings,
  getExternalMappingsSuccess,
  deleteExternalMapping,
  deleteExternalMappingSuccess,
  selectExternalMapping,
  upsertExternalMapping,
  upsertExternalMappingSuccess,
  unselectExternalMapping,
  uploadExcelFile,
  downloadTemplate,
  downloadTemplateComplete
};
