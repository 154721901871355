import { CoreActions } from '@app/modules/core/actions/core.actions';
import { createReducer, on } from '@ngrx/store';

export const featureKey = 'core';

export interface State {
  loadingFeatureModule: boolean;
  loggedIn: boolean;
  roles: string[];
  tenants: string[];
  token: string;
  username: string;
  givenname: string;
  familyname: string;
  activeModule: string;
}

export const initialState: State = {
  loadingFeatureModule: false,
  loggedIn: false,
  roles: [],
  tenants: [],
  token: null,
  username: null,
  givenname: null,
  familyname: null,
  activeModule: ''
};

export const reducer = createReducer(
  initialState,
  on(CoreActions.loadingLazyModuleStarted, state => ({
    ...state,
    loadingFeatureModule: true
  })),

  on(CoreActions.loadingLazyModuleFinished, state => ({
    ...state,
    loadingFeatureModule: false
  })),

  on(
    CoreActions.updateAuthState,
    (
      state,
      { loggedIn, token, roles, tenants, username, givenname, familyname }
    ) => ({
      ...state,
      loggedIn,
      token,
      roles,
      tenants,
      username,
      givenname,
      familyname
    })
  ),

  on(CoreActions.setActiveModule, (state, { activeModule }) => ({
    ...state,
    activeModule
  }))
);

export const selectLoadingFeatureModuleState = (state: State) =>
  state.loadingFeatureModule;

export const selectAuthState = (s: State) => ({
  loggedIn: s.loggedIn,
  roles: s.roles,
  username: s.username,
  givenname: s.givenname,
  familyname: s.familyname
});

export const selectToken = (s: State) => ({
  token: s.token
});

export const selectTenants = (s: State) => ({
  tenants: s.tenants
});

export const selectActiveModule = (s: State) => ({
  activeModule: s.activeModule
});
