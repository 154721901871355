import { Injectable } from '@angular/core';
import { ToastActions } from '@app/modules/core/actions/toast.action';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { saveAs } from 'file-saver';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ExternalMappingActions } from '../actions/externalmapping.actions';
import { FileImportActions } from '../actions/file-import.actions';
import { ExternalMappingService } from '../services/externalmapping.service';

@Injectable()
export class ExternalMappingEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly externalMappingService: ExternalMappingService
  ) {}

  onGetExternalMappings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ExternalMappingActions.getExternalMappings),
      switchMap(req =>
        this.externalMappingService.get(req.filterParams).pipe(
          map(listResult =>
            ExternalMappingActions.getExternalMappingsSuccess({
              listResult
            })
          ),
          catchError(error => {
            return of(ToastActions.errorToast({ message: error.error }));
          })
        )
      )
    )
  );

  onDeleteExternalMapping$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ExternalMappingActions.deleteExternalMapping),
      switchMap(req =>
        this.externalMappingService.delete(req.externalMapping).pipe(
          switchMap(_ => [
            ExternalMappingActions.deleteExternalMappingSuccess(),
            ToastActions.successToast({
              message: 'Successfully deleted'
            }),
            ExternalMappingActions.getExternalMappings({})
          ]),
          catchError(error => {
            return of(ToastActions.errorToast({ message: error.error }));
          })
        )
      )
    )
  );

  onUpsertExternalMapping$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ExternalMappingActions.upsertExternalMapping),
      switchMap(req =>
        this.externalMappingService.upsert(req.externalMapping).pipe(
          map(_ => ExternalMappingActions.upsertExternalMappingSuccess()),
          catchError(error => {
            return of(ToastActions.errorToast({ message: error.error }));
          })
        )
      )
    )
  );

  onSuccessfulUpsertExternalMapping$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ExternalMappingActions.upsertExternalMappingSuccess),
      switchMap(_ => [
        ToastActions.successToast({
          message: 'Successfully saved'
        }),
        ExternalMappingActions.getExternalMappings({}),
        ExternalMappingActions.unselectExternalMapping()
      ])
    )
  );

  onUploadExternalMappingExcelFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ExternalMappingActions.uploadExcelFile),
      switchMap(req =>
        this.externalMappingService.excelImport(req.excelFile).pipe(
          map(_ =>
            FileImportActions.closeUploadFileDialog({ HotelChain: null })
          ),
          catchError(error =>
            error.error instanceof ProgressEvent
              ? of(
                  FileImportActions.importFail({
                    errors:
                      'Please check that the file is not open in a program and try again.\r\n ------ \r\nThe server may have been temporarily unavailable.\r\nPlease try again later or contact support.'
                  })
                )
              : of(FileImportActions.importFail({ errors: error.error }))
          )
        )
      )
    )
  );

  downloadTemplate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ExternalMappingActions.downloadTemplate),
      switchMap(({ templateType }) =>
        this.externalMappingService.getTemplate(templateType).pipe(
          map(fileData =>
            ExternalMappingActions.downloadTemplateComplete({
              fileData,
              templateType
            })
          ),
          catchError(error =>
            of(ToastActions.errorToast({ message: error.message }))
          )
        )
      )
    )
  );

  downloadTemplateComplete$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ExternalMappingActions.downloadTemplateComplete),
        map(parameter =>
          saveAs(
            parameter.fileData.body,
            `${parameter.templateType.toString()}-Import.xlsx`
          )
        )
      ),
    { dispatch: false }
  );
}
