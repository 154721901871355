import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ApiEndpointsService } from '../../core/services/api-endpoints.service';
import { ExternalMapping } from '../data/externalmapping';
import { FilterParams } from '../data/filterparam';
import { ListResult } from '../data/listResult';
import { TemplateType } from '../data/templateType';

@Injectable({
  providedIn: 'root'
})
export class ExternalMappingService {
  lastParams: FilterParams;

  constructor(
    private readonly http: HttpClient,
    private readonly api: ApiEndpointsService
  ) {}

  get(filterParams?: FilterParams): Observable<ListResult<ExternalMapping>> {
    filterParams = { ...this.lastParams, ...filterParams };
    const result = this.http.get<ListResult<ExternalMapping>>(
      this.api.endpoints.dynamicConnect + '/externalmapping/Get',
      {
        params: filterParams
      }
    );
    this.lastParams = filterParams;
    return result;
  }

  delete(externalMapping: ExternalMapping): Observable<string> {
    return this.http.delete<string>(
      this.api.endpoints.dynamicConnect + '/externalmapping/Delete',
      {
        body: externalMapping
      }
    );
  }

  upsert(externalMapping: ExternalMapping): Observable<string> {
    return this.http.post<string>(
      this.api.endpoints.dynamicConnect + '/externalmapping/Upsert',
      externalMapping
    );
  }

  excelImport(excelFile: File): Observable<string> {
    const formData = new FormData();
    formData.append('excelFile', excelFile);

    const options = {
      reportProgress: true
    };

    return this.http.post<string>(
      this.api.endpoints.dynamicConnect + '/ExternalMapping/ExcelImport',
      formData,
      options
    );
  }

  getTemplate(templateType: TemplateType): Observable<HttpResponse<Blob>> {
    return this.http.get(this.api.endpoints.dynamicConnect + '/File/template', {
      params: {
        templateType
      },
      responseType: 'blob',
      observe: 'response'
    });
  }
}

export class ExternalMappingServiceStub {
  /* istanbul ignore next */
  get(_filterParams?: FilterParams): Observable<ListResult<ExternalMapping>> {
    return of({} as ListResult<ExternalMapping>);
  }
  /* istanbul ignore next */
  upsert(_externalMapping: ExternalMapping): Observable<string> {
    return of('');
  }
  /* istanbul ignore next */
  delete(_externalMapping: ExternalMapping): Observable<string> {
    return of('');
  }
  excelImport(_excelFile: File): Observable<string> {
    return of('');
  }
  getTemplate(_templateType: TemplateType): Observable<HttpResponse<Blob>> {
    return of(new HttpResponse<Blob>());
  }
}
