import { Component } from '@angular/core';
import { Router } from '@angular/router';
import * as fromApp from '@app/reducers';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CoreActions } from '../../actions/core.actions';

@Component({
  selector: 'app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.scss']
})
export class AppHeaderComponent {
  authState$: Observable<any>;
  username$: Observable<any>;
  activeModule$: Observable<string>;

  constructor(
    private readonly store: Store<fromApp.State>,
    public readonly router: Router
  ) {
    this.authState$ = store.pipe(select(fromApp.selectAuthState));
    this.username$ = this.authState$.pipe(
      map(auth => (auth.loggedIn ? auth.username : 'Anonymous'))
    );

    this.activeModule$ = store.pipe(
      select(fromApp.selectActiveModule),
      map(am => am.activeModule)
    );
  }

  loginOrOut() {
    this.authState$
      .subscribe(auth =>
        this.store.dispatch(
          auth.loggedIn ? CoreActions.userLogout() : CoreActions.userLogin()
        )
      )
      .unsubscribe();
  }
}
