import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CoreModule } from './modules/core/core.module';
import { RoleGuard } from './modules/core/guards/role.guard';

export const routesForCore: Routes = [
  {
    path: '',
    canActivate: [RoleGuard],
    loadChildren: () =>
      import('./modules/booking/booking.module').then(mod => mod.BookingModule)
  }
];

export const routesForDynamicConnect: Routes = [
  {
    path: '',
    canActivate: [RoleGuard],
    loadChildren: () =>
      import('./modules/dynamic-connect/dynamic-connect.module').then(
        mod => mod.DynamicConnectModule
      )
  }
];

const isCurrentDomainDynamicConnect: boolean =
  window.location.hostname.startsWith('dynamic-connect');

@NgModule({
  imports: [
    RouterModule.forRoot(
      isCurrentDomainDynamicConnect ? routesForDynamicConnect : routesForCore,
      {
        initialNavigation: 'disabled',
        canceledNavigationResolution: 'computed',
        onSameUrlNavigation: 'reload'
      }
    ),
    CoreModule
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
