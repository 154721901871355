<div class="header-wrapper" fxLayout="column" fxLayoutAlign="start stretch">
  <div
    class="header"
    fxFlex="row"
    fxLayoutAlign="space-between center"
    fxFlexFill
  >
    <div fxFlex="row" fxLayoutAlign="start center" fxLayoutGap="30px">
      <dsa-application-title
        *ngIf="(activeModule$ | async) === 'core'"
        [href]="'/'"
        >DER Touristik Core</dsa-application-title
      >
      <dsa-application-title
        *ngIf="(activeModule$ | async) === 'dcco'"
        [href]="'/'"
        >Dynamic Connect</dsa-application-title
      >
    </div>

    <div fxFlex="row" fxLayoutAlign="end center" fxLayoutGap="10px">
      <app-notifications-display
        *ngIf="
          (authState$ | async)?.loggedIn && (activeModule$ | async) === 'dcco'
        "
      ></app-notifications-display>

      <dsa-personal-menu
        [buttonText]="username$ | async"
        [headerTopLine]="username$ | async"
        [headerBottomLine]="
          (authState$ | async)?.loggedIn ? '' : 'Please log in'
        "
      >
        <dsa-personal-menu-item (click)="loginOrOut()" [dividerTop]="true">
          <dsa-icon item-icon>exit_to_app</dsa-icon>
          <div class="loginMenuItemText">
            {{ (authState$ | async)?.loggedIn ? "Logout" : "Login" }}
          </div>
        </dsa-personal-menu-item>
      </dsa-personal-menu>
    </div>
  </div>

  <dsa-navigationbar></dsa-navigationbar>
</div>
