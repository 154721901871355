import { HttpResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { HotelChainDropdown } from '../../shared/data/hotel-chain-dropdown';
import { FilterParams } from '../data/filterparam';
import { HotelChain } from '../data/hotel-chain';
import { HotelChainIndex } from '../data/hotel-chain-index';
import { SearchOutput } from '../data/searchOutput';
import { TemplateType } from '../data/templateType';

const searchHotelChains = createAction(
  '[HotelChain] Search Hotel Chains',
  props<{
    filterParams?: FilterParams;
    force: boolean;
  }>()
);

const searchHotelChainsSuccess = createAction(
  '[HotelChain] Search Hotel Chains successfully',
  props<{
    searchOutput: SearchOutput<HotelChainIndex>;
  }>()
);

const getHotelChain = createAction(
  '[HotelChain] Get Hotel Chain',
  props<{
    hotelChainCode: string;
    supplier: string;
  }>()
);

const getHotelChainSuccess = createAction(
  '[HotelChain] Get Hotel Chain successfully',
  props<{
    hotelChain: HotelChain;
  }>()
);

const uploadExcelFile = createAction(
  '[HotelChain] Upload Hotel Excel File',
  props<{
    excelFile: File;
    hotelChainCode: string;
    supplier: string;
    overrideHotels: boolean;
    templateType: TemplateType;
  }>()
);

const downloadTemplate = createAction(
  '[HotelChain] Download Template',
  props<{ templateType: TemplateType }>()
);

const downloadTemplateComplete = createAction(
  '[HotelChain] Download Template Complete',
  props<{ fileData: HttpResponse<Blob>; templateType: TemplateType }>()
);

const getDropdown = createAction('[HotelChain] Get HotelChain Dropdown');

const getDropdownSuccess = createAction(
  '[HotelChain] Get HotelChain Dropdown successfully',
  props<{
    hotelChainDropdown: HotelChainDropdown[];
  }>()
);

const validateHotelChain = createAction(
  '[HotelChain] Validate HotelChain',
  props<{
    hotelChainCode: string;
    supplier: string;
  }>()
);

const validateHotelChainFailure = createAction(
  '[Hotel] Validate Hotel Failure',
  props<{ errorMessage: string }>()
);

const setSaveButtonDisabledSuccess = createAction(
  '[Hotel] Set SaveButton Disabled Success'
);

const setSaveButtonDisabled = createAction(
  '[HotelChain] Set HotelChain SaveButton Disabled',
  props<{ isDisabled: boolean }>()
);

const deleteHotelChain = createAction(
  '[HotelChain] Delete HotelChain',
  props<{ hotelChainIndex: HotelChainIndex }>()
);

const deleteHotelChainSuccess = createAction(
  '[HotelChain] Delete HotelChain Success'
);

const upsertHotelChain = createAction(
  '[HotelChain] Upserting HotelChain',
  props<{ hotelChain: HotelChain; successMessage?: string }>()
);

const upsertHotelChainNavigateBackOnSuccess = createAction(
  '[HotelChain] Upserting HotelChain and navigate to Hotel Chains on success',
  props<{ hotelChain: HotelChain; successMessage?: string }>()
);

const upsertHotelChainSuccess = createAction(
  '[HotelChain] Upserting HotelChain success',
  props<{ successMessage?: string }>()
);

const upsertHotelChainError = createAction(
  '[HotelChain] Upserting HotelChain error',
  props<{ error: string }>()
);

export const HotelChainActions = {
  getHotelChain,
  getHotelChainSuccess,
  searchHotelChains,
  searchHotelChainsSuccess,
  getDropdown,
  getDropdownSuccess,
  uploadExcelFile,
  validateHotelChain,
  validateHotelChainFailure,
  setSaveButtonDisabledSuccess,
  setSaveButtonDisabled,
  deleteHotelChain,
  deleteHotelChainSuccess,
  upsertHotelChain,
  upsertHotelChainSuccess,
  upsertHotelChainNavigateBackOnSuccess,
  upsertHotelChainError,
  downloadTemplate,
  downloadTemplateComplete
};
