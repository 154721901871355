import { Injectable } from '@angular/core';
import { ToastActions } from '@app/modules/core/actions/toast.action';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AriActions } from '../actions/ari.actions';
import { AriService } from '../services/ari.service';

@Injectable()
export class AriEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly ariService: AriService
  ) {}

  onDeleteHotelAri$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AriActions.deleteHotelAri),
      switchMap(req =>
        this.ariService
          .deleteHotel(
            req.hotel.Code,
            req.hotel.HotelChainCode,
            req.hotel.Supplier
          )
          .pipe(
            map(_ => AriActions.deleteAriSuccess()),
            catchError(error =>
              of(ToastActions.errorToast({ message: error.message }))
            )
          )
      )
    )
  );

  onDeleteAriSuccessSuccessToast$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AriActions.deleteAriSuccess),
      map(_ =>
        ToastActions.successToast({
          message: 'The ari has been successfully deleted.'
        })
      )
    )
  );
}
