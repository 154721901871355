import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { CoreActions } from '@app/modules/core/actions/core.actions';
import * as fromRoot from '@app/reducers';
import { select, Store } from '@ngrx/store';
import { OAuthService } from 'angular-oauth2-oidc';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard {
  private roles: string[];
  private loggedIn: boolean;

  constructor(
    private readonly store: Store<fromRoot.State>,
    private readonly oAuthService: OAuthService
  ) {
    store.pipe(select(fromRoot.selectAuthState)).subscribe(authInfo => {
      this.loggedIn = authInfo.loggedIn;
      this.roles = authInfo.roles;
    });
  }

  canActivate(next: ActivatedRouteSnapshot, _: RouterStateSnapshot): boolean {
    return this.hasAccess(next);
  }

  private hasAccess(next: ActivatedRouteSnapshot) {
    if (!this.loggedIn) {
      this.store.dispatch(CoreActions.userLogin());
      return false;
    }

    if (!this.oAuthService.hasValidAccessToken()) {
      this.store.dispatch(CoreActions.userLogin());
      return false;
    }

    if (
      next.data &&
      next.data.allowedRoles &&
      !this.isInRole(next.data.allowedRoles)
    ) {
      this.store.dispatch(CoreActions.accessDenied());
      return false;
    }
    return true;
  }

  private isInRole(allowedRoles: string[]) {
    return allowedRoles.some(r => this.roles.includes(r));
  }
}
