import { Component } from '@angular/core';
import { Brand, DER } from '@dsa/design-system-angular';
import { environment } from '@env/environment';
import { select, Store } from '@ngrx/store';
import { OAuthService } from 'angular-oauth2-oidc';
import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks';
import { Observable } from 'rxjs';
import packageInfo from '../../package.json';
import * as fromApp from './reducers';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  authState$: Observable<any>;
  loadingFeatureModule$: Observable<boolean>;
  copyright = `©DER Touristik Deutschland GmbH - ${environment.name} - v${packageInfo.version}`;
  brand: Brand = DER;
  constructor(store: Store<fromApp.State>, oauthService: OAuthService) {
    this.loadingFeatureModule$ = store.pipe(
      select(fromApp.selectLoadingFeatureModuleState)
    );

    this.authState$ = store.pipe(select(fromApp.selectAuthState));

    oauthService.configure(environment.authConfig);
    oauthService.tokenValidationHandler = new JwksValidationHandler();
    oauthService.loadDiscoveryDocumentAndTryLogin();
    oauthService.setupAutomaticSilentRefresh();
  }
}
